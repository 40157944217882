import AOS from "aos";

if (window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
  AOS.init({
    disable: true,
  });
} else {
  AOS.init({
    once: true,
  });
}
